import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24')
];

export const server_loads = [0];

export const dictionary = {
		"/": [5],
		"/FAQ": [6],
		"/bookmarks": [7],
		"/cookie-policy": [8],
		"/games": [9],
		"/games/[category]": [10],
		"/games/[category]/[gameId]": [11,[],[2]],
		"/google-search": [12],
		"/horoscope": [13],
		"/news": [14],
		"/news/[category]": [15],
		"/news/[category]/[postId]": [16,[],[3]],
		"/search-results": [17],
		"/speed-test": [18],
		"/sports": [19],
		"/terms-of-service": [20],
		"/us-elections/home": [21,[4]],
		"/us-elections/results": [~22,[4]],
		"/weather": [23],
		"/wiki-bytes": [24]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';